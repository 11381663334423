import React from 'react';
import ReactDOM from 'react-dom';
import '@Assets/css/eduact/nucleo-icons.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from '@Features/store';
import GlobalStyles from '@Assets/globalStyles';
// import "@material/react-snackbar/dist/snackbar.css";
import SnackProvider from '@Providers/useSnackbar';
import {
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';
import { theme } from '@Styled/theme';
import { ThemeProvider } from 'styled-components';
import '@Utils/types';
import { enableMapSet } from 'immer';

enableMapSet();

ReactDOM.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<MuiThemeProvider theme={theme}>
				<ThemeProvider theme={theme as any}>
					<Provider store={store}>
						<SnackProvider>
							<GlobalStyles />
							<App />
						</SnackProvider>
					</Provider>
				</ThemeProvider>
			</MuiThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
