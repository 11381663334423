import React from 'react';
import { IAllProps } from '@tinymce/tinymce-react';

export const BaseTinyMceConfig: IAllProps = {
	apiKey: 'zotibeoxd21ns3cxitfm3imap5ya5wagfslzekrhzidraxit',
	init: {
		height: 200,
		width: '100%',
		// menubar: false,
		branding: false,
		// plugins: [
		// 	'advlist',
		// 	'autolink',
		// 	'lists',
		// 	'link',
		// 	'image',
		// 	'charmap',
		// 	'anchor',
		// 	'searchreplace',
		// 	'visualblocks',
		// 	'code',
		// 	'fullscreen',
		// 	'insertdatetime',
		// 	'media',
		// 	'table',
		// 	'preview',
		// 	'help',
		// 	'wordcount',
		// 	'imagetools',
		// ],
		plugins:
			'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
		// toolbar:
		// 	'undo redo | blocks | ' +
		// 	'bold italic forecolor | alignleft aligncenter ' +
		// 	'alignright alignjustify | bullist numlist outdent indent | ' +
		// 	'removeformat | preview | image | help',
		menubar: 'file edit view insert format tools table help',
		toolbar:
			'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
		content_style:
			'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
		file_picker_types: 'image',
		toolbar_sticky: true,
		autosave_ask_before_unload: true,
		autosave_interval: '30s',
		autosave_prefix: '{path}{query}-{id}-',
		autosave_restore_when_empty: false,
		autosave_retention: '2m',
		image_advtab: true,
		importcss_append: true,
		// templates: [
		// 	{ title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
		// 	{ title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
		// 	{ title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
		//   ],
		quickbars_selection_toolbar:
			'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
		noneditable_class: 'mceNonEditable',
		toolbar_mode: 'sliding',
		contextmenu: 'link image table',
		images_upload_handler: async (blob, progress) => {
			alert('UPLOAD');
			// console.log(blob);
			return '';
		},
	},
};
